@import "~@amzn/meridian-tokens/base/_color.scss";

body {
  background-color: $color-gray-50;
}

.leaflet-container {
  height: calc(100vh - 270px);
  width: 100%;
}

.add-yard-span {
  margin: 12.6px;
}

.blue-500 {
  color: $color-blue-500;
}

.custom-expander-header {
  height: 40px;
  cursor: pointer;
}

.custom-expander-header:focus {
  outline: none;
}

.location-list {
  display: flex;
  flex-wrap: wrap;
}

.location-button {
  font-family: "Amazon Ember", Arial, sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
  width: 90px;
  padding: 2px 6px;
  border-radius: 4px;
  margin: 0 2px 2px 0;
  cursor: pointer;
  user-select: none;
}

.location-button:hover {
  background-color: $color-gray-translucent-dark-200;
}

.location-button-selected {
  font-weight: 400;
  background-color: $color-gray-translucent-dark-200;
}

.location-button-modified {
  font-weight: bold;
  color: $color-yellow-500;
}

.location-button-new {
  font-weight: bold;
  color: $color-green-500;
}

.location-button-removed {
  color: $color-gray-400;
}

.cursor-grab {
  cursor: grab;
}

.cursor-default {
  cursor: default;
}

.cursor-grabbing {
  cursor: grabbing;
}
.cursor-grabbing * {
  cursor: grabbing;
}

.base-table-cell-new {
  font-weight: bold;
  color: $color-green-500;
}
.base-table-cell-modified-row {
  font-weight: bold;
  font-style: italic;
}
.base-table-cell-modified-cell {
  font-weight: bold;
  font-style: italic;
  color: $color-yellow-500;
}

.base-table-cell-new-modified {
  font-weight: bold;
}

.unselectable {
  user-select: none;
}

.drag-icon {
  font-size: 20px;
  user-select: none;
  cursor: grab;
}

.communication-panel-cameras-slider {
  padding: 10px;
}

.camera-panel {
  width: 100%;
  height: 380px;
}

.camera-thumbnail-wrapper {
  margin: 0px 5px;
  width: 100px !important;
  height: 35px;
}

.camera-type-tooltip {
  position: absolute;
  left: 0;
  top: 0;
  padding: 6px 10px;
  background: rgba(11, 12, 12, 0.3);
  color: white;
  border-radius: 2px;
  z-index: 10;
  p {
    padding: 0px;
    margin: 0px;
    line-height: 18px;
    text-transform: capitalize;
  }
}

.communication-panel-live-camera-section {
  margin: auto;
  padding: 4px 4px 4px 4px !important;
}

.communication-panel-bottom-section {
  .audio-voice-container {
    .audio-button-inactive {
      .audio-icon {
        color: black;
      }
    }
    .audio-button-active {
      .audio-icon {
        color: white;
      }
    }
  }
}

.text-aligned-center {
  text-align: center;
}

.video-mask-container {
  position: "relative";
  width: 720px;
  height: 576px;
  margin: 8px 0px 8px 0px;
}

.video-mask-overlay {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.3;
  height: 576px;
  width: 720px;
}

.masking-video {
  position: absolute;
  left: 0;
  top: 0;
  height: 576px;
  width: 720px;
}

.camera-selected {
  position: relative;
}

.button-section {
  z-index: 1;
  position: absolute;
  right: 1vh;
  bottom: 1vh;

  .button-controls {
    flex-direction: column;
    background: rgba(0, 0, 0, 0.65);
    border-radius: 0;
    width: 96px;
    height: 60px;
    font-size: 12px;
    margin: 0;
  }

  .button-controls-text {
    font-size: 10px;
    color: white;
    font-weight: 300;
    margin: 0;
  }

  .button-control-icon {
    color: white;
  }
}

.centered-button-section {
  right: 50%;
  bottom: 50%;
  transform: translate(50%, 50%);
}
